<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r">
        <el-button
          @click="toDetail(true)"
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          >添加</el-button
        >
      </template>
      <template slot="right-r" slot-scope="{ data }"
        ><el-button
          icon="el-icon-upload2"
          class="export-btn"
          @click="exportData(data.params)"
          >导出</el-button
        ></template
      >
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(false, data.row)"
          >查看详情</el-button
        >
        <el-button type="text" @click="toDetail(true, data.row)"
          >编辑</el-button
        >
        <el-button
          @click="expertDelete(data.row)"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </div>
      <div slot="userEnable" slot-scope="{ data }">
        <span v-if="data.row.userEnable" style="color: #00bc0d">启用</span>
        <span v-else style="color: #f64a2d">禁用</span>
      </div>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import {
  getExpertList,
  deleteExpert,
  exportDataExpert,
} from "@/api/safeDuty.js";
export default {
  components: { SimpleTable },
  data() {
    return {
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "pageIndex",
        currentSizeKey: "pageSize",
        totalKey: "total",
        height: 560,
        searchConfigs: [
          {
            label: "专家编号",
            type: "input",
            modelKey: "serialNo",
          },
          {
            label: "姓名",
            type: "input",
            modelKey: "name",
          },
          {
            label: "所属地区",
            type: "input",
            modelKey: "ownershipAddress",
          },
          {
            label: "账号状态",
            type: "select",
            modelKey: "userEnable",
            option: [
              { dictKey: "", dictValue: "全部" },
              { dictKey: true, dictValue: "启用" },
              { dictKey: false, dictValue: "禁用" },
            ],
          },
          {
            label: "三方机构",
            type: "input",
            modelKey: "workUnit",
          },
          {
            label: "手机号",
            type: "input",
            modelKey: "phone",
          },
          {
            label: "更新时间",
            type: "daterange",
            daterangeKeys: ["updateDateTimeStart", "updateDateTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "updateT",
          },
          {
            label: "录入时间",
            type: "daterange",
            daterangeKeys: ["createDateTimeStart", "createDateTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "serialNo",
            label: "专家编号",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "name",
            label: "姓名",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "phone",
            label: "手机号",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "workUnit",
            label: "所属三方机构",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "district",
            label: "所属地区",
            width: "",
            isShow: true,
          },
          {
            prop: "userEnable",
            label: "账号状态",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "createTime",
            label: "录入时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "updateTime",
            label: "更新时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams) {
      let res = await getExpertList(queryParams);
      return {
        localData: res.data.records || [],
        total: res.data.total,
      };
    },
    // 跳转详情或编辑
    toDetail(edit = false, item = {}) {
      this.$router.push({
        name: "safeDutyExpertManagementDetail",
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
    expertDelete(item) {
      this.$confirm("删除后对应数据也将清理，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteExpert({ expertId: item.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    exportData(data = {}) {
      console.log(111111);
      exportDataExpert(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
